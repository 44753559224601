import React from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import { Helmet } from 'react-helmet';

export type NotFoundSEOProps = {
  imageUrl: string;
};

const NotFoundSEO = ({ imageUrl }: NotFoundSEOProps) => {
  const intl = useIntl();
  return (
    <Helmet
      defaultTitle={intl.formatMessage({
        id: 'home-seo.title',
      })}
      lang={intl.formatMessage({
        id: 'seo.lang',
      })}
      description={intl.formatMessage({
        id: 'home-seo.description',
      })}
      meta={[
        {
          name: `description`,
          content: intl.formatMessage({
            id: 'home-seo.description',
          }),
        },
        {
          property: `og:title`,
          content: intl.formatMessage({
            id: 'home-seo.title',
          }),
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: `og:url`,
          content: `https://www.promptinterface.ai/${intl.locale}/`,
        },
        {
          property: `og:image`,
          content: `https://www.promptinterface.ai${imageUrl}`,
        },
        {
          property: `og:description`,
          content: intl.formatMessage({
            id: 'home-seo.description',
          }),
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: `twitter:title`,
          content: intl.formatMessage({
            id: 'home-seo.title',
          }),
        },
        {
          name: `twitter:image`,
          content: `https://www.promptinterface.ai${imageUrl}`,
        },
        {
          name: `twitter:description`,
          content: intl.formatMessage({
            id: 'home-seo.description',
          }),
        },
        {
          name: 'twitter:site',
          content: '@ToolPad_fr',
        },
        {
          name: 'twitter:creator',
          content: '@ToolPad_fr',
        },
      ]}
    />
  );
};

export default NotFoundSEO;
